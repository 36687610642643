export const getEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        status
        jobTitle
      }
      nextToken
    }
  }
`;

export const getEmployeesWithContacts = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limitEmployees: Int
    $limitContacts: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limitEmployees, nextToken: $nextToken) {
      items {
        id
        name
        contacts(limit: $limitContacts) {
          items {
            id
            first_name
            last_name
            primary_email
            updatedAt
            createdAt
            state
            zip_code
            company {
              id
              name
            }
            vip_type
            vip
          }
        }
      }
    }
  }
`;

export const getEmployeesWithCompanys = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limitEmployees: Int
    $limitCompanys: Int
    $limitContacts: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limitEmployees, nextToken: $nextToken) {
      items {
        id
        name
        companys(limit: $limitCompanys) {
          items {
            id
            name
            vip
            city
            state
            zip_code
            createdAt
            updatedAt
            assignedTo {
              id
              name
            }
            contacts(limit: $limitContacts) {
              items {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const getEmployeeWithContacts = /* GraphQL */ `
  query GetEmployee(
    $id: ID!
    $limitContacts: Int
    $nextTokenContacts: String
  ) {
    getEmployee(id: $id) {
      id
      name
      contacts(limit: $limitContacts, nextToken: $nextTokenContacts) {
        items {
          id
          first_name
          last_name
          primary_email
          updatedAt
          createdAt
          state
          zip_code
          vip
          vip_type
          company {
            id
            name
          }
          groups {
            items {
              id
              group {
                id
                name
                type
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const getEmployeeWithCompanys = /* GraphQL */ `
  query GetEmployee(
    $id: ID!
    $limitCompanys: Int
    $limitContacts: Int
    $nextTokenCompanys: String
  ) {
    getEmployee(id: $id) {
      id
      name
      companys(limit: $limitCompanys, nextToken: $nextTokenCompanys) {
        items {
          id
          name
          vip
          city
          state
          zip_code
          createdAt
          updatedAt
          vip_type
          assignedTo {
            id
            name
          }
          contacts(limit: $limitContacts) {
            items {
              id
            }
          }
          groups {
            items {
              id
              group {
                id
                name
                type
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const getEmployeeWithContactsForExport = /* GraphQL */ `
  query GetEmployee(
    $id: ID!
    $limitContacts: Int
    $nextTokenContacts: String
  ) {
    getEmployee(id: $id) {
      id
      name
      contacts(limit: $limitContacts, nextToken: $nextTokenContacts) {
        items {
          first_name
          last_name
          primary_email
          updatedAt
          createdAt
          state
          zip_code
          vip_type
          assignedTo {
            name
          }
          company {
            name
          }
        }
        nextToken
      }
    }
  }
`;

export const getEmployeeWithCompanysForExport = /* GraphQL */ `
  query GetEmployee(
    $id: ID!
    $limitCompanys: Int
    $nextTokenCompanys: String
  ) {
    getEmployee(id: $id) {
      id
      name
      companys(limit: $limitCompanys, nextToken: $nextTokenCompanys) {
        items {
          name
          city
          state
          zip_code
          createdAt
          updatedAt
          vip_type
          assignedTo {
            name
          }
        }
        nextToken
      }
    }
  }
`;