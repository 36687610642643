export const getCompanysWithName = /* GraphQL */ `
  query ListCompanysByName(
    $type: String
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanysByName(type: $type, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
      items {
        id
        name
        email
      }
      nextToken
    }
  }
`;

export const getCompanyWithContacts = /* GraphQL */ `
  query GetCompany(
    $id: ID!
    $limitContacts: Int
  ) {
    getCompany(id: $id) {
      id
      name
      contacts(limit: $limitContacts) {
        items {
          id
          first_name
          last_name
          primary_email
          updatedAt
          createdAt
          state
          zip_code
          company {
            id
            name
          }
          groups {
            items {
              id
              group {
                id
                name
                type
              }
            }
          }
          assignedTo {
            id
          }
        }
      }
    }
  }
`;

// export const getCompanysWithContacts = /* GraphQL */ `
//   query ListCompanys(
//     $filter: ModelCompanyFilterInput
//     $limitCompanys: Int
//     $limitContacts: Int
//     $nextToken: String
//   ) {
//     listCompanys(filter: $filter, limit: $limitCompanys, nextToken: $nextToken) {
//       items {
//         id
//         name
//         contacts(limit: $limitContacts) {
//           items {
//             id
//             first_name
//             last_name
//             primary_email
//             primary_phone
//             updatedAt
//             createdAt
//             state
//             zip_code
//             company {
//               id
//               name
//             }
//           }
//         }
//       }
//     }
//   }
// `;

export const getCompanysTotal = /* GraphQL */ `
 query ListCompanys(
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const getCompanyContactActivity = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      createdAt
      createdBy
      updatedAt
      mailchimp_id
      groups {
        items {
          id
          group {
            id
            name
            mailchimp_list_id
          }
        }
      }
      contacts {
        items {
          id
          full_name
          createdAt
          notes {
            items {
              id
              message
              owner
              createdAt
              updatedAt
            }
            nextToken
          }
          contactForms {
            items {
              id
              type
              message
              createdAt
              updatedAt
            }
            nextToken
          }
          downloadForms {
            items {
              id
              type
              firstName
              lastName
              productId
              productName
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      notes {
        items {
          id
          message
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          assignedTo {
            id
            name
            jobTitle
            email
            createdAt
            updatedAt
          }
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          createdAt
          updatedAt
          eventAttendeeEventId
          typeAttendee
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneHour
            startTimeZoneName
            endDate
            endTime
            endTimeZoneHour
            endTimeZoneName
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;

export const getCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limitCompanys: Int
    $limitContacts: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limitCompanys, nextToken: $nextToken) {
      items {
        id
        name
        vip
        vip_type
        city
        state
        zip_code
        createdAt
        updatedAt
        assignedTo {
          id
          name
        }
        contacts(limit: $limitContacts) {
          items {
            id
          }
        }
        groups {
          items {
            id
            group {
              id
              name
              type
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getCompanysByName = /* GraphQL */ `
  query ListCompanysByName(
    $type: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limitCompanys: Int
    $limitContacts: Int
    $nextToken: String
  ) {
    listCompanysByName(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limitCompanys
      nextToken: $nextToken
    ) {
      items {
        id
        name
        vip
        vip_type
        city
        state
        zip_code
        createdAt
        updatedAt
        assignedTo {
          id
          name
        }
        contacts(limit: $limitContacts) {
          items {
            id
          }
        }
        groups {
          items {
            id
            group {
              id
              name
              type
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getCompanysByCreatedAt = /* GraphQL */ `
  query ListCompanysByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limitCompanys: Int
    $limitContacts: Int
    $nextToken: String
  ) {
    listCompanysByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limitCompanys
      nextToken: $nextToken
    ) {
      items {
        id
        name
        vip
        vip_type
        city
        state
        zip_code
        createdAt
        updatedAt
        assignedTo {
          id
          name
        }
        contacts(limit: $limitContacts) {
          items {
            id
          }
        }
        groups {
          items {
            id
            group {
              id
              name
              type
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getCompanysByUpdatedAt = /* GraphQL */ `
  query ListCompanysByUpdatedAt(
    $type: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limitCompanys: Int
    $limitContacts: Int
    $nextToken: String
  ) {
    listCompanysByUpdatedAt(
      type: $type
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limitCompanys
      nextToken: $nextToken
    ) {
      items {
        id
        name
        vip
        vip_type
        city
        state
        zip_code
        createdAt
        updatedAt
        assignedTo {
          id
          name
        }
        contacts(limit: $limitContacts) {
          items {
            id
          }
        }
        groups {
          items {
            id
            group {
              id
              name
              type
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getCompanysWithAssignedToJustIds = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assignedTo {
          id
          name
        }
      }
      nextToken
    }
  }
`;

// List of companies with groups
export const getCompanysWithGroups = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        groups {
          items {
            id
            group {
              id
              name
              type
            }
          }
        }
      }
      nextToken
    }
  }
`;

// Get one company with groups
export const getCompanyWithGroups = /* GraphQL */ `
  query GetCompany(
    $id: ID!
  ) {
    getCompany(id: $id) {
      id
      name
      groups {
        items {
          id
          group {
            id
            name
            type
          }
        }
      }
    }
  }
`;

export const getCompanysWithGroupCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limitCompanys: Int
    $limitContacts: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limitCompanys, nextToken: $nextToken) {
      items {
        id
        name
        vip
        vip_type
        city
        state
        zip_code
        createdAt
        updatedAt
        assignedTo {
          id
          name
        }
        contacts(limit: $limitContacts) {
          items {
            id
          }
        }
        groups {
          items {
            id
            group {
              id
              name
              type
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const searchCompanysCustom = /* GraphQL */ `
  query SearchCompanys(
    $filter: SearchableCompanyFilterInput
    $sort: SearchableCompanySortInput
    $limit: Int
    $nextToken: String
    $limitContacts: Int
  ) {
    searchCompanys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        vip
        vip_type
        city
        state
        email
        zip_code
        createdAt
        updatedAt
        assignedTo {
          id
          name
        }
        contacts(limit: $limitContacts) {
          items {
            id
          }
        }
        groups {
          items {
            id
            group {
              id
              name
              type
            }
          }
        }
      }
      nextToken
      total
    }
  }
`;

export const searchCompanysList = /* GraphQL */ `
  query SearchCompanys(
    $filter: SearchableCompanyFilterInput
    $sort: SearchableCompanySortInput
    $limit: Int
    $nextToken: String
  ) {
    searchCompanys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
      }
      nextToken
      total
    }
  }
`;

// export const getCompanysByNameAndEmail = /* GraphQL */ `
//   query ListCompanysByName(
//     $type: String
//     $limit: Int
//     $nextToken: String
//     $sortDirection: ModelSortDirection
//   ) {
//     listCompanysByName(type: $type, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
//       items {
//         id
//         name
//         email
//       }
//       nextToken
//     }
//   }
// `;

export const getPipelinesByCompanyContacts = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      type
      name
      createdAt
      updatedAt
      createdBy
      contacts {
        items {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          pipeline {
            items {
              id
              type
              phase
              sale_amount
              createdAt
              updatedAt
              project_title
              description
              history {
                phase
                createdAt
                description
              }
              contactId
              employeeId
              searchName
              archived
              closedLostReason
              sendNotification
              contact {
                id
                type
                first_name
                last_name
                full_name
                primary_email
                primary_phone
                street_address
                city
                state
                country
                zip_code
                title
                vip
                vip_type
                createdAt
                updatedAt
                createdBy
                mailchimp_id
                sendNotification
              }
              assignedTo {
                id
                name
                jobTitle
                email
                reportFormData
                status
                createdAt
                updatedAt
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;