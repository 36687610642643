import React from "react";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Form } from 'react-final-form';
import styled from "styled-components";

const Name = styled.span`
  font-weight: bold;
  font-style: italic;
`;

const AlertForm = ({ onSubmit, initialValues, id, collection, dialogText }) => {

  const getText = () => {
    switch(id){
      case "unlink-product": 
        return (
          <Typography variant="subtitle1">
            Are you sure you want to unlink <Name>{initialValues.entryName}</Name> from <Name>{collection.name}</Name>?
          </Typography>
        )
      case "delete-collection":
        return (
          <Typography variant="subtitle1">
            Are you sure you want to delete <Name>{initialValues.entryName}</Name>?
          </Typography>
        )
      case "deploy-website":
        return (
          <>
            <Typography variant="subtitle1" gutterBottom={true}>
              Are you sure you want to deploy?
            </Typography>
            <Typography variant="subtitle1">
              This action updates {initialValues.companyName}'s website.
            </Typography>
          </>
        )
      case "cancel-changes":
        return (
          <Typography variant="subtitle1">
            Are you sure you want to cancel the changes made?
          </Typography>
        )
      case "publish":
        return (
          <Typography variant="subtitle1">
            Are you sure you want to publish the changes made?
          </Typography>
        )
      case "delete-contact-customer":
        const contacts = initialValues.selected.length;
        return (
          <div>
            <Typography variant="subtitle1">
              Are you sure you want to delete {contacts === 1 ? 'the selected contact' : `${contacts} contacts`}?
            </Typography>
            <Typography variant="subtitle2">
              This will remove them from the Pipeline if applicable.
            </Typography>
          </div>
        )
      case "delete-company":
        const companies = initialValues.selected.length;
        return (
          <Typography variant="subtitle1">
            Are you sure you want to delete {companies === 1 ? 'the selected company' : `${companies} companies`}?
          </Typography>
        )
      case "update-mailchimp-status": return dialogText()
      case "retry-mailchimp-subscribe": return dialogText()
      case "deauthorize-gmail": return dialogText()
      default:
        return (
          <Typography variant="subtitle1">
            Are you sure you want to delete?
          </Typography>
        )
      case "delete-pipeline":
        const deletes = initialValues.selected.length;
        return (
          <div>
            <Typography variant="subtitle1">
              Are you sure you want to delete {deletes === 1 ? 'the selected contact' : `${deletes} contacts from the pipeline`}?
            </Typography>
          </div>
        )
        case "archive-pipeline":
        const archives = initialValues.selected.length;
        return (
          <div>
            <Typography variant="subtitle1">
              Are you sure you want to archive {archives === 1 ? 'the selected contact' : `${archives} contacts from the pipeline`}?
            </Typography>
          </div>
        )
    }
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <Box onSubmit={handleSubmit} component="form" width={320} id={id} children={getText()}/>
      )}
    </Form>
  );
};


export default AlertForm;